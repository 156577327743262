#skills {
  overflow: hidden;
  width: 100vw;
  max-width: 100rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 0, auto;
}

.skillTitle {
  margin: 1rem 0;
  font-size: 3rem;
}

.skillDesc {
  font-weight: 200;
  font-size: 1rem;
  max-width: 51rem;
  padding: 0 4rem;
}

.skillBars {
  margin: 1.5rem;
  width: 100vw;
  max-width: 51rem;
  text-align: left;
}

.skillBar {
  display: flex;
  margin: 1rem;
  padding: 1.5rem 2rem;
  border-radius: 0.5rem;
  background: rgb(50, 50, 50);
}

.skillBarImg {
  object-fit: cover;
  height: 4rem;
  width: 4rem;
  margin-right: 2rem;
}

.skillBarText > ul {
  font-size: 0.9rem;
  font-weight: 200;
  line-height: 1.75rem;
}

@media screen and (max-width: 720px) {
  .skillBarText {
    font-size: 3vw;
  }
  .skillBarImg {
    height: 2.25rem;
    width: 2.25rem;
  }
  .skillBarText > h2 {
    font-size: 5vw;
  }
}
