#intro {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  position: relative;
}

.bg {
  position: absolute;
  top: -4rem;
  right: 0;
  z-index: -1;
  object-fit: cover;
  height: 100vh;
  width: 100%;
}

.introContent {
  height: 100vh;
  width: 100vh;
  padding: 2rem;
  font-size: 3rem;
  font-weight: 700;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.hello {
  font-size: 1.75rem;
  font-weight: 200;
  margin-right: 25rem;
}

.introName {
  background: linear-gradient(90deg, #ffffff, goldenrod 90%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-right: 17rem;
}

.introPara {
  font-size: medium;
  font-weight: 300;
  margin-top: 0.5rem;
  margin-left: 8.5rem;
  text-align: start;
}

.button {
  background: white;
  margin: 1rem 0;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 2rem;
  display: inline-flex;
  align-items: center;
}

.buttonImg {
  object-fit: cover;
  margin: 0 0.25rem;
  height: 1.75rem;
}

@media screen and (max-width: 840px) {
  .bg {
    right: -10vw;
  }
  .introContent {
    font-size: 10vw;
  }
  .hello {
    font-size: 4.5vw;
    margin-right: auto; /* Center align hello text */
  }
  .introName {
    margin-right: auto; /* Center align introName text */
  }
  .introPara {
    margin-left: auto; /* Center align introPara text */
    text-align: center; /* Center align introPara text */
  }
}

@media screen and (max-width: 480px) {
  .bg {
    right: -10vw;
  }
}
